import { createContext } from 'react';

const FFv2Context = createContext<FFv2ContextType>({
    freezeState: ''
});

export type FFv2ContextType = {
    freezeState: string;
};

export default FFv2Context;
