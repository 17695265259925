import { Pivot, PivotItem } from '@fluentui/react';
import React from 'react';

import { Card } from '../../../components/Card';
import { defaultTab, flightStatusValues } from '../configs/defaults';
import { FlightStatusFilterTabsCardStyles } from '../styles/MyFlightsStyle';
import { FlightStatusFilter, FlightsFiltered } from '../types/Types';

type FlightTabsProps = {
    switchFlightStatusFilter: (filter: FlightStatusFilter) => void;
    flights: FlightsFiltered | undefined;
    isAdminView?: boolean;
    onAdminToolsClick?: () => void;
    selectedTab?: string;
    onTabClick?: (itemKey: string) => void;
};

/**

 * Displays the flight tabs.
 *
 * @param props The props for the component.
 * @returns The flight tabs.
 */
const FlightTabs: React.FC<FlightTabsProps> = (props) => {
    return (
        <Card styles={FlightStatusFilterTabsCardStyles}>
            <Pivot onLinkClick={onTabClick} selectedKey={props.selectedTab} className="flight-status-tabs" overflowBehavior="menu">
                {flightStatusValues.map((tab) => (
                    <PivotItem
                        itemKey={tab.key}
                        headerText={`${tab.title}${
                            !tab.disabled && props.flights?.[tab.key].length !== undefined ? ` (${props.flights?.[tab.key].length})` : ''
                        }`}
                        key={tab.key}
                        headerButtonProps={{
                            disabled: tab.disabled,
                            style: tab.disabled ? { color: 'grey' } : undefined,
                            'data-id': `flight-status-pivot`,
                            'data-value': tab.title
                        }}
                    />
                ))}

                {props.isAdminView && (
                    <PivotItem
                        itemKey="admin-tools"
                        headerText="Admin Tools"
                        key="extraTab"
                        itemIcon="DeveloperTools"
                        headerButtonProps={{
                            'data-id': `admin-flight-tools`,
                            'data-value': 'Admin Tools'
                        }}
                    ></PivotItem>
                )}
            </Pivot>
        </Card>
    );

    function onTabClick(item?: PivotItem, _ev?: React.MouseEvent<HTMLElement>) {
        const value = item?.props.itemKey ?? 'all';
        const selectedFilter = flightStatusValues.find((v) => v.key === value);

        if (selectedFilter) {
            props.switchFlightStatusFilter(selectedFilter);
        } else {
            props.switchFlightStatusFilter(defaultTab);
        }

        if (props.onTabClick) {
            props.onTabClick(value);
        }
    }
};

export default FlightTabs;
