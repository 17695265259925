import { DefaultButton, MessageBar, MessageBarType, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Card } from '../../components/Card';
import ErrorNotification from '../../components/ErrorNotification/ErrorNotification';
import { teamsClientRelease } from '../../services/configs/msGraph.defaults';
import FeatureFlightService from '../../services/featureFlight.service';
import { appInsightsClient } from '../../utils/appInsightsUtility';
import { getUserContext, getUserInfo, getUserPrincipalName } from '../../utils/userUtility';

import EditFlightForm from './AdminFlightsComponents/EditFlightForm';
import FFv2Context, { FFv2ContextType } from './contexts/FFv2Context';
import UserContext, { UserContextType } from './contexts/UserContext';
import FlightFlagSettingsCard from './FlightInfoComponents/FeatureFlagConfigsCard';
import FlightDetailsCard from './FlightInfoComponents/FlightDetailsCard';
import FlightLinksCard from './FlightInfoComponents/FlightLinksCard';
import FlightStatusCard from './FlightInfoComponents/FlightStatusCard';
import FlightTitleCard from './FlightInfoComponents/FlightTitleCard';
import FlightUpdates from './FlightInfoComponents/FlightUpdates';
import RollbackForm from './FlightsComponents/RollbackForm';
import { gapStackTokensMedium } from './styles/FFv2Style';
import { FlightRequest, RollbackOption } from './types/flightRequest.types';
import { Blocker, FlightUpdate, ProcessedFlightRollout } from './types/Types';
import { getCachedData, processFlights } from './utilities/FFv2Utils';

/**
 * Displays the page that includes extra flight information.
 * In this case, it is the flight object itself. But in the future
 * will be a more stylized version of it.
 *
 * @returns The page with flight info.
 */
const FlightInfo: React.FC = () => {
    const location = useLocation();
    const [userContext, setUserContext] = useState<UserContextType>({
        securityGroups: [],
        directReports: [],
        peers: []
    });
    const [ffv2Context, setFFv2Context] = useState<FFv2ContextType>({
        freezeState: ''
    });
    const currentUser = getUserInfo();
    const isTeamsClientRelease = userContext.securityGroups.some((group) => group.displayName === teamsClientRelease);

    const { isOpenedFromMyFlights } = location.state || { isOpenedFromMyFlights: false };

    const { flightId } = useParams<{ flightId: string }>();
    const [flight, setFlight] = useState<ProcessedFlightRollout>();
    const [updates, setUpdates] = useState<FlightUpdate[]>([]);
    const [flightRequests, setFlightRequests] = useState<Record<string, FlightRequest>>({});
    const [blockers, setBlockers] = useState<Blocker[]>([]);
    const [isEditFlightModalOpen, { setTrue: showEditFlightModal, setFalse: hideEditFlightModal }] = useBoolean(false);
    const [isRollbackFormOpen, { setTrue: showRollbackForm, setFalse: hideRollbackForm }] = useBoolean(false);
    const [defaultRollbackOption, setDefaultRollbackOption] = useState<RollbackOption>(RollbackOption.revert);

    const [isDataLoaded, { setTrue: setIsDataLoaded, setFalse: setIsDataNotLoaded }] = useBoolean(false);
    const [isLoading, { setTrue: setIsLoading, setFalse: setIsNotLoading }] = useBoolean(true);

    useEffect(() => {
        fetchFlight();
        getUserContext()
            .then((context) => setUserContext(context))
            .catch((fail) => {
                console.error(`Caught error getting user context, error: ${fail}`);
                appInsightsClient.logException(
                    { exception: fail },
                    {
                        message: `Caught error in useEffect getUserContext in Flight Info page`,
                        user: getUserPrincipalName()
                    }
                );
            });
        new FeatureFlightService()
            .getFreeze()
            .then((response) => {
                setFreezeState(response.state);
            })
            .catch((fail) => {
                console.error(`Caught error getting freeze state, error: ${fail}`);
                appInsightsClient.logException(
                    { exception: fail },
                    {
                        message: `Caught error in useEffect getFreeze in Flight Info page`,
                        user: getUserPrincipalName(),
                        group: teamsClientRelease
                    }
                );
            });
    }, []);

    const setFreezeState = (freezeState: string) => {
        setFFv2Context({ ...ffv2Context, freezeState: freezeState });
    };

    const updateFlight = () => {
        fetchFlight();
    };

    const updateSubscribed = (isSubscribed: boolean) => {
        if (flight) {
            setFlight({ ...flight, isSubscribed });
            const currentCache = getCachedData('ffv2FlightData');
            if (currentCache) {
                const newData = (currentCache.data as ProcessedFlightRollout[]).map((item) => {
                    if (item.id === flight.id) {
                        return { ...item, isSubscribed };
                    }
                    return item;
                });
                localStorage.setItem('ffv2FlightData', JSON.stringify({ ...currentCache, data: newData }));
            }
        }
    };

    return (
        <>
            {!isLoading && !isDataLoaded && (
                <Card>
                    <ErrorNotification msg={`Uh oh looks like there was an issue fetching info for flight ${flightId}`} />
                </Card>
            )}
            {isLoading && (
                <Card>
                    <Spinner size={SpinnerSize.large} label="Fetching flight info" />
                </Card>
            )}
            {!isLoading && isDataLoaded && flight && (
                <UserContext.Provider value={userContext}>
                    <FFv2Context.Provider value={ffv2Context}>
                        <Stack tokens={gapStackTokensMedium}>
                            <EditFlightForm
                                isOpen={isTeamsClientRelease && isEditFlightModalOpen}
                                onDismiss={hideEditFlightModal}
                                flight={flight}
                                updateFlight={updateFlight}
                            />
                            <RollbackForm
                                isOpen={isTeamsClientRelease && isRollbackFormOpen}
                                onDismiss={hideRollbackForm}
                                selectedFlight={flight}
                                updateFlight={updateFlight}
                                defaultOperation={defaultRollbackOption}
                            />
                            <Stack horizontal tokens={gapStackTokensMedium}>
                                {isOpenedFromMyFlights && (
                                    <DefaultButton
                                        iconProps={{ iconName: 'RevToggleKey' }}
                                        text="Return to My Flights"
                                        ariaLabel="ReturnToMyFlights"
                                        onClick={() => window.history.back()}
                                        styles={{ root: { width: 'fit-content' } }}
                                    />
                                )}
                                {(currentUser.id === flight.createdBy.id ||
                                    [...userContext.peers, ...userContext.directReports, userContext.manager, userContext.skip]
                                        .map((item) => item?.id)
                                        .includes(currentUser.id) ||
                                    flight.isSubscribed) &&
                                    flight.state.ring !== 'ring0' && (
                                        <>
                                            <DefaultButton
                                                text="Rollback"
                                                ariaLabel="Rollback to R0"
                                                onClick={() => {
                                                    showRollbackForm();
                                                    setDefaultRollbackOption(RollbackOption.rollback);
                                                }}
                                                styles={{ root: { width: 'fit-content' } }}
                                            />
                                            <DefaultButton
                                                text="Revert"
                                                ariaLabel="Revert from Current Ring"
                                                onClick={() => {
                                                    showRollbackForm();
                                                    setDefaultRollbackOption(RollbackOption.revert);
                                                }}
                                                styles={{ root: { width: 'fit-content' } }}
                                            />
                                        </>
                                    )}
                                {isTeamsClientRelease && (
                                    <DefaultButton
                                        text="Edit Flight"
                                        ariaLabel="EditFlight"
                                        onClick={showEditFlightModal}
                                        styles={{ root: { width: 'fit-content' } }}
                                    />
                                )}
                            </Stack>
                            {ffv2Context.freezeState && ffv2Context.freezeState !== 'Operational' && (
                                <MessageBar messageBarType={MessageBarType.severeWarning}>
                                    FFv2 is in the freeze state of {ffv2Context.freezeState}. You may still approve or reject flights, but
                                    they will not proceed until freeze ends.
                                </MessageBar>
                            )}
                            <Stack horizontal tokens={gapStackTokensMedium}>
                                <FlightTitleCard flight={flight} updateSubscribed={updateSubscribed} />
                                <FlightStatusCard flight={flight} blockers={blockers} />
                            </Stack>
                            <Stack horizontal tokens={gapStackTokensMedium}>
                                <FlightDetailsCard flight={flight} />
                                <Stack tokens={gapStackTokensMedium} styles={{ root: { width: '60%' } }}>
                                    <Stack.Item grow>
                                        <FlightFlagSettingsCard flight={flight} />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <FlightLinksCard flight={flight} />
                                    </Stack.Item>
                                </Stack>
                            </Stack>
                            <FlightUpdates updates={updates} flightRequests={flightRequests} fetchFlight={fetchFlight} flight={flight} />
                        </Stack>
                    </FFv2Context.Provider>
                </UserContext.Provider>
            )}
        </>
    );

    function fetchFlight() {
        setIsLoading();
        setIsDataNotLoaded();

        if (!flightId) {
            setIsDataNotLoaded();
            setIsNotLoading();
            return;
        }
        new FeatureFlightService()
            .getRolloutsById(flightId)
            .then((response) => {
                if (response.length === 0) throw new Error('No flight found with id ' + flightId);

                setFlight(processFlights(response)[0]);
                setIsDataLoaded();

                appInsightsClient.logEvent({
                    name: 'FFv2:FlightInfo:LoadFlightInfo',
                    properties: {
                        flightId: flightId
                    }
                });
            })
            .catch((error) => {
                console.error(error);
                setIsDataNotLoaded();
            })
            .finally(() => {
                setIsNotLoading();
            });

        new FeatureFlightService()
            .getRolloutBlockers([parseInt(flightId)])
            .then((response) => {
                setBlockers(response[parseInt(flightId)] ?? []);
            })
            .catch((error) => {
                console.error(error);
            });

        new FeatureFlightService()
            .getRolloutUpdates('rolloutId', flightId)
            .then((response) => {
                setUpdates(response);
            })
            .catch((error) => {
                console.error(error);
            });

        new FeatureFlightService()
            .getFlightRequestsByFlightId(flightId)
            .then((response) => {
                setFlightRequests(response);
            })
            .catch((error) => {
                console.error(error);
            });
    }
};

export { FlightInfo };
