import { FFV2_AZURE_FUNCTION_URL } from '../configs/env.config';
import { RollbackFormData } from '../pages/FFv2/FlightsComponents/RollbackForm';
import { FlightRequest, FlightRequestUpdateForm } from '../pages/FFv2/types/flightRequest.types';
import {
    ADOWorkItem,
    BaseFilters,
    BlockFlightFormData,
    FeatureFlightRollout,
    StartFlightFormData,
    ApprovalItem,
    ApprovalFormData,
    FeedbackFormData,
    CheckBlockersResponse,
    ExistingRolloutsData,
    FeatureFlags,
    Filters,
    EditFlightFormData,
    FreezeState,
    FlightUpdate,
    FlightSubmitResult,
    ValidateFeatureFlagResponse,
    GeneralExceptionFormData,
    AddCloudToRollout,
    RolloutFeaturesResponse
} from '../pages/FFv2/types/Types';
import { InvalidADOItemState } from '../pages/FFv2/utilities/FFv2Errors';

import ApiService from './base/api.service';
import ApiManager from './base/apiManager';
import { Service } from './configs/apiServiceConfig';
import ApiResponseError from './models/error/ApiResponseError';
import { FlightBoardingPassValidity } from './models/FeatureFlight/FlightBoardingPassValidity';
import { SupportedFilters } from './models/FeatureFlight/SupportedFilters';

/**
 * FeatureFlightService class provides methods to interact with the AzureFunctionApi.
 */
class FeatureFlightService {
    private apiService: ApiService;

    /**
     * Constructs a new FeatureFlightService instance.
     */
    constructor() {
        this.apiService = ApiManager.getApiService(Service.FFV2);
    }

    /**
     * Retrieves a list of supported filters for rollouts.
     *
     * @returns The supported filters for rollouts.
     */
    async getSupportedFilters(): Promise<SupportedFilters> {
        const endpoint = '/api/rollout/getSupportedFilters';
        const url = this.getApiUrl(endpoint);

        try {
            return await this.apiService.get<SupportedFilters>(url);
        } catch (error) {
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Retrieves a list of supported user filters for rollouts.
     *
     * @returns The supported user filters for rollouts.
     */
    async getSupportedUserFilters(): Promise<SupportedFilters> {
        const endpoint = '/api/rollout/getSupportedUserFilters';
        const url = this.getApiUrl(endpoint);

        try {
            return await this.apiService.get<SupportedFilters>(url);
        } catch (error) {
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Initializes a rollout with optional data.
     *
     * @param data Optional data for initializing the rollout.
     * @returns The initialized rollout data.
     */
    async initializeRollout(data: StartFlightFormData): Promise<FlightSubmitResult> {
        const endpoint = '/api/rollout/initializeRollout';
        const url = this.getApiUrl(endpoint);

        try {
            return await this.apiService.post<FlightSubmitResult>(url, data);
        } catch (error) {
            const apiResponseError = error as ApiResponseError;
            if (apiResponseError.statusCode === 400 && apiResponseError.errorDetail.startsWith('Invalid related work item state')) {
                throw new InvalidADOItemState(apiResponseError.errorDetail);
            }
            this.handleApiError(apiResponseError);
            throw error;
        }
    }

    /**
     * Checks for the existence of rollouts based on provided filters and feature flags.
     *
     * @param filters - The filters to apply when checking for rollout existence.
     * @param featureFlags - The set of feature flags to consider in the rollout check.
     * @param [userFilters] - The user filters to apply when checking for rollout existence.
     * @returns A promise that resolves to the data of existing rollouts.
     */
    async checkRolloutExistence(filters: Filters, featureFlags: FeatureFlags, userFilters?: BaseFilters): Promise<ExistingRolloutsData> {
        const endpoint = '/api/rollout/checkRolloutExistence';
        const url = this.getApiUrl(endpoint);

        try {
            return await this.apiService.post<ExistingRolloutsData>(url, { filters, userFilters, featureFlags });
        } catch (error) {
            const apiResponseError = error as ApiResponseError;
            if (apiResponseError.statusCode === 400 && apiResponseError.errorDetail.startsWith('Invalid related work item state')) {
                throw new InvalidADOItemState(apiResponseError.errorDetail);
            }
            this.handleApiError(apiResponseError);
            throw error;
        }
    }

    /**
     * Validates a boarding pass based on the provided boardingPassId.
     *
     * @param boardingPassId The ID of the boarding pass to validate.
     * @returns The validity status of the boarding pass.
     */
    async validateBoardingPass(boardingPassId: number): Promise<FlightBoardingPassValidity> {
        const endpoint = `/api/workitem/${boardingPassId}/validateBoardingPass`;
        const url = this.getApiUrl(endpoint);

        try {
            return await this.apiService.get<FlightBoardingPassValidity>(url);
        } catch (error) {
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Retrieves a list of rollouts for the given users.
     * If no users are provided, the rollouts for all users are retrieved.
     *
     * @param param The param for which rollouts are to be retrieved.
     * @param paramType The type of index. Either rolloutId, userPrincipalName or userId.
     * @returns The list of rollouts for the given param.
     */
    private async getRollouts(param?: string, paramType?: string): Promise<FeatureFlightRollout[]> {
        if (param && !paramType) throw new Error('paramType is required when param is provided');
        if (paramType && paramType !== 'userPrincipalName' && paramType !== 'rolloutId' && paramType !== 'userId') {
            throw new Error('paramType must be either rolloutId, userPrincipalName or userId');
        }

        const endpoint = `/api/rollout${param ? `?${paramType}=${param}` : ''}`;
        const url = this.getApiUrl(endpoint);

        try {
            return await this.apiService.get<FeatureFlightRollout[]>(url);
        } catch (error) {
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Retrieves a list of rollouts for the given users by userPrincipalName.
     *
     * @param user The users for which rollouts are to be retrieved.
     * @returns The list of rollouts for the given users.
     */
    async getRolloutsByUserPrincipalName(user: string | string[]): Promise<FeatureFlightRollout[]> {
        return await this.getRollouts(Array.isArray(user) ? user.join(',') : user, 'userPrincipalName');
    }

    /**
     * Retrieves a list of rollouts for the given users by id.
     *
     * @param user The users for which rollouts are to be retrieved.
     * @returns The list of rollouts for the given users.
     */
    async getRolloutsByUserId(user: string | string[]): Promise<FeatureFlightRollout[]> {
        return await this.getRollouts(Array.isArray(user) ? user.join(',') : user, 'userId');
    }

    /**
     * Retrieves a list of rollouts by their id.
     *
     * @param id The ids for which rollouts are to be retrieved.
     * @returns The list of rollouts for the given ids.
     */
    async getRolloutsById(id: string | string[]): Promise<FeatureFlightRollout[]> {
        return await this.getRollouts(Array.isArray(id) ? id.join(',') : id, 'rolloutId');
    }

    /**
     * Retrieves a list of rollouts for all users.
     *
     * @returns The list of rollouts for all users.
     */
    async getAllRollouts(): Promise<FeatureFlightRollout[]> {
        return await this.getRollouts();
    }

    /**
     * Sends request to create a flight blocker for rollout.
     *
     * @param rolloutId The rollout id for which flight blocker is to be created.
     * @param data The data for creating flight blocker.
     * @returns Flight blocker bug details.
     */
    async blockRollout(rolloutId: string, data: BlockFlightFormData): Promise<ADOWorkItem> {
        const endpoint = `/api/rollout/${rolloutId}/block`;
        const url = this.getApiUrl(endpoint);

        try {
            return await this.apiService.post<ADOWorkItem>(url, data);
        } catch (error) {
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Sends request to approve or reject a flight.
     *
     * @param rolloutId The id of the rollout to be approved or rejected.
     * @param data The data for update approval status.
     * @returns Flight approval details.
     */
    async approveRollout(rolloutId: string, data: ApprovalFormData): Promise<ApprovalItem> {
        const endpoint = `api/rollout/${rolloutId}/approval`;

        const url = this.getApiUrl(endpoint);
        try {
            return await this.apiService.post<ApprovalItem>(url, data);
        } catch (error) {
            try {
                this.handleApiError(error as ApiResponseError);
            } catch (e) {
                console.error((error as ApiResponseError).statusCode);
                throw new ApiResponseError((error as ApiResponseError).statusCode, `${(e as Error).message}`);
            }
            throw error;
        }
    }

    /**
     * Creates a feedback work item with the given data.
     *
     * @param data The feedback form data.
     * @returns A promise that resolves to the created work item.
     * @throws The created work item.
     */
    async createFeedbackWorkItem(data: FeedbackFormData): Promise<ADOWorkItem> {
        const endpoint = `/api/feedback`;
        const url = this.getApiUrl(endpoint);

        try {
            if (data.screenshot) {
                const file = data.screenshot;
                const base64String = await new Promise<string>((resolve, reject) => {
                    const reader = new FileReader();

                    reader.onload = (event) => {
                        resolve(event.target?.result as string);
                    };

                    reader.onerror = (event) => {
                        reject(event.target?.error);
                    };

                    reader.readAsDataURL(file);
                });
                const screenshot = {
                    name: file.name,
                    content: base64String.substring(base64String.indexOf(',') + 1)
                };
                return await this.apiService.post<ADOWorkItem>(url, {
                    ...data,
                    screenshot,
                    parent: {
                        id: 3382568,
                        url: 'https://domoreexp.visualstudio.com/ad4dc0b3-25d1-44f6-a766-8670592cc9a9/_apis/wit/workItems/3382568'
                    }
                });
            } else {
                return await this.apiService.post<ADOWorkItem>(url, data);
            }
        } catch (error) {
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Checks all blockers for the given rollout ids.
     *
     * @param rolloutIds The ids of the rollouts to check blockers for.
     * @returns The response containing the blockers for the given rollouts.
     */
    async getRolloutBlockers(rolloutIds: number[]): Promise<CheckBlockersResponse> {
        const endpoint = `api/getRolloutBlockers?rolloutId=${rolloutIds.join(',')}`;
        const url = this.getApiUrl(endpoint);
        try {
            return await this.apiService.get<CheckBlockersResponse>(url);
        } catch (error) {
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Edits a rollout with the specified ID.
     *
     * @param rolloutId - The ID of the rollout to edit.
     * @param data - The data containing the changes to apply to the rollout.
     * @returns A promise that resolves to a boolean indicating whether the edit was successful.
     * @throws If an error occurs during the API call or handling the error response.
     */
    async editRollout(rolloutId: number, data: EditFlightFormData): Promise<FeatureFlightRollout> {
        const endpoint = `api/rollout/${rolloutId}`;
        const url = this.getApiUrl(endpoint);
        try {
            return await this.apiService.put<FeatureFlightRollout>(url, data);
        } catch (error) {
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Retrieves the freeze state from the API.
     *
     * @returns A Promise that resolves to the FreezeState object.
     * @throws {ApiResponseError} If an error occurs while making the API request.
     */
    async getFreeze(): Promise<FreezeState> {
        const endpoint = `api/freeze`;
        const url = this.getApiUrl(endpoint);
        try {
            return await this.apiService.get<FreezeState>(url);
        } catch (error) {
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Freezes or unfreezes a feature flight.
     *
     * @param isFreezed - A boolean indicating whether to freeze or unfreeze the feature flight.
     * @returns A Promise that resolves to a boolean indicating the success of the operation.
     * @throws {ApiResponseError} If an error occurs while making the API request.
     */
    async setFreeze(isFreezed: boolean): Promise<boolean> {
        const endpoint = `api/freeze`;
        const url = this.getApiUrl(endpoint);
        try {
            return await this.apiService.post<boolean>(url, { isFreezed: isFreezed ? 'true' : 'false' });
        } catch (error) {
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Retrieves the tutorial progress for a specific page.
     *
     * @param pageName - The name of the page.
     * @returns A Promise that resolves to the tutorial progress as a number.
     * @throws {ApiResponseError} If an error occurs while making the API request.
     */
    async getTutorialProgress(pageName: string): Promise<number> {
        const endpoint = `api/tutorial?page=${pageName}`;
        const url = this.getApiUrl(endpoint);
        try {
            const response = await this.apiService.get<number>(url);
            return Number(response.toString());
        } catch (error) {
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Sets the tutorial progress for a specific page.
     *
     * @param pageName - The name of the page.
     * @param progress - The progress value.
     * @returns A promise that resolves to a boolean indicating whether the tutorial progress was successfully set.
     */
    async setTutorialProgress(pageName: string, progress: number): Promise<boolean> {
        const endpoint = `api/tutorial`;
        const url = this.getApiUrl(endpoint);
        const body = {
            page: pageName,
            progress
        };
        try {
            return await this.apiService.post<boolean>(url, body);
        } catch (error) {
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Checks the eligibility for an experiment.
     *
     * @param data - The data required for checking eligibility.
     * @returns A promise that resolves to a boolean indicating whether the experiment is eligible or not.
     */
    async checkExpEligibility(data: StartFlightFormData): Promise<boolean> {
        const endpoint = `api/rollout/checkExpEligibility`;
        const url = this.getApiUrl(endpoint);
        const body = {
            client: data.client,
            category: data.category,
            audience: data.audience,
            featureFlags: data.featureFlags,
            filters: data.filters,
            userFilters: data.userFilters,
            createdBy: data.createdBy,
            controlConfigs: data.controlConfigs
        };
        try {
            return await this.apiService.post<{ isEligible: boolean }>(url, body).then((response) => {
                return response.isEligible;
            });
        } catch (error) {
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Updates the state of a flight.
     *
     * @param flightId - The ID of the flight.
     * @param state - The new state of the flight.
     * @returns A Promise that resolves to void.
     */
    async updateFlightStatus(flightId: string, state: string): Promise<void> {
        const endpoint = `api/rollout/${flightId}/updateRolloutStatus`;
        const url = this.getApiUrl(endpoint);
        try {
            await this.apiService.put(url, { state });
        } catch (error) {
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Updates the manually paused status of a flight.
     *
     * @param flightId - The ID of the flight.
     * @param isManuallyPaused - The manually paused status to be updated.
     * @param manuallyPausedReason - The reason for pausing the flight.
     * @returns A Promise that resolves to void.
     */
    async updateManuallyPaused(flightId: string, isManuallyPaused: boolean, manuallyPausedReason?: string): Promise<void> {
        const endpoint = `api/rollout/${flightId}/updateRolloutStatus`;
        const url = this.getApiUrl(endpoint);
        try {
            const payload = {
                isManuallyPaused,
                ...(manuallyPausedReason && { manuallyPausedReason })
            };
            await this.apiService.put(url, payload);
        } catch (error) {
            console.log(error);
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Retrieves rollout updates based on the specified parameters.
     *
     * @param paramType - The type of parameter to filter the updates.
     * @param param - The value of the parameter to filter the updates.
     * @returns A promise that resolves to an array of FlightUpdate objects.
     */
    async getRolloutUpdates(paramType: 'rolloutId' | 'updateType', param: string): Promise<FlightUpdate[]> {
        if (paramType !== 'rolloutId' && paramType !== 'updateType') throw new Error('Invalid paramType');
        const endpoint = `/api/rolloutUpdates${param ? `?${paramType}=${param}` : ''}`;
        const url = this.getApiUrl(endpoint);
        try {
            return await this.apiService.get<FlightUpdate[]>(url);
        } catch (error) {
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Subscribes to a rollout.
     *
     * @param rolloutId - The ID of the rollout to subscribe to.
     * @returns A promise that resolves to void.
     */
    async subscribe(rolloutId: string): Promise<void> {
        const endpoint = `/api/rollout/${rolloutId}/subscribe`;
        const url = this.getApiUrl(endpoint);
        try {
            await this.apiService.post(url, {});
        } catch (error) {
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Unsubscribes from a rollout.
     *
     * @param rolloutId - The ID of the rollout to unsubscribe from.
     * @returns A promise that resolves to void.
     */
    async unsubscribe(rolloutId: string): Promise<void> {
        const endpoint = `/api/rollout/${rolloutId}/unsubscribe`;
        const url = this.getApiUrl(endpoint);
        try {
            await this.apiService.post(url, {});
        } catch (error) {
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Validates the feature flags.
     *
     * @param featureFlags - The feature flags to validate.
     * @param schemaOnly - A boolean indicating whether to validate only the schema.
     * @returns A promise that resolves to the validation response.
     */
    async validateFeatureFlags(featureFlags: FeatureFlags, schemaOnly = false): Promise<ValidateFeatureFlagResponse> {
        const endpoint = '/api/validateFeatureFlags';
        const url = this.getApiUrl(endpoint);

        try {
            return await this.apiService.post<ValidateFeatureFlagResponse>(url, {
                featureFlags,
                schemaOnly
            });
        } catch (error) {
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Creates a flight request with the given data.
     *
     * @param flightId The ID of the flight for which the request is to be created.
     * @param data The data for creating the flight request.
     * @returns A promise that resolves to the created flight request.
     */
    async createFlightRequest(flightId: string, data: RollbackFormData): Promise<FlightRequest> {
        const endpoint = `/api/rollout/${flightId}/flightRequest`;
        const url = this.getApiUrl(endpoint);

        try {
            return await this.apiService.post<FlightRequest>(url, data);
        } catch (error) {
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Retrieves the flight requests for the given flight ID.
     *
     * @param flightId  The ID of the flight for which the requests are to be retrieved.
     * @returns A promise that resolves to a record of flight requests.
     */
    async getFlightRequestsByFlightId(flightId: string): Promise<Record<string, FlightRequest>> {
        const endpoint = `/api/rollout/${flightId}/flightRequest`;
        const url = this.getApiUrl(endpoint);

        try {
            return await this.apiService.get<Record<string, FlightRequest>>(url);
        } catch (error) {
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Retrieves the flight request by the given request ID.
     *
     * @param requestId The ID of the request to retrieve.
     * @param data The data for updating the flight request.
     * @returns A promise that resolves to the updated flight request.
     */
    async updateFlightRequest(requestId: string, data: FlightRequestUpdateForm): Promise<FlightRequest> {
        const endpoint = `/api/flightRequest/${requestId}`;
        const url = this.getApiUrl(endpoint);

        try {
            return await this.apiService.put<FlightRequest>(url, data);
        } catch (error) {
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Generates the API URL with the endpoint and API key.
     *
     * @param endpoint The endpoint to construct the URL.
     * @returns The generated API URL.
     * @private
     */
    private getApiUrl(endpoint: string): string {
        const url = new URL(endpoint, FFV2_AZURE_FUNCTION_URL);
        return url.toString();
    }

    /**
     * Handles API errors and throws custom error messages based on the error code.
     *
     * @param error The API error to handle.
     * @private
     */
    private handleApiError(error: ApiResponseError): void {
        let errorMessage = 'Failed to perform the operation';

        if (error.statusCode === 403) {
            errorMessage =
                'Unable to connect to the server. If this problem persists, please contact @FFv2 in the BQTR channel for support.\n';
        } else if (error.statusCode === 500) {
            errorMessage = `Internal server error. If this problem persists, please contact @FFv2 in the BQTR channel for support.\nDetails: ${error.errorDetail}`;
        } else if (error.statusCode === 409) {
            errorMessage = `Operation conflict with server. Details: ${error.errorDetail}`;
        } else if (error.statusCode === 400) {
            errorMessage = `Bad request: ${error.errorDetail}`;
        } else if (error.statusCode === 404) {
            errorMessage = `Not Found. Details: ${error.errorDetail}`;
        } else if (error.errorDetail) {
            errorMessage = error.errorDetail;
        }

        throw new Error(errorMessage);
    }

    /**
     * Creates an ADO exception task with the given data.
     *
     * @param rolloutId The rollout id for which exception is to be created.
     * @param data The data for creating the ADO exception task.
     * @param data.title Title of ADO exception task.
     * @param data.description Description of ADO exception task.
     * @param data.exceptionType Exception type of ADO exception task.
     * @param data.flightId The ID of the flight.
     * @returns A promise that resolves to the created ADO task.
     */
    async createAdoExceptionTask(rolloutId: string, data: GeneralExceptionFormData): Promise<ADOWorkItem> {
        const endpoint = `/api/rollout/${rolloutId}/createExceptionTask`;
        const url = this.getApiUrl(endpoint);

        try {
            return await this.apiService.post(url, data);
        } catch (error) {
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Retrieves a list of available clouds.
     *
     * @returns The list of available clouds.
     */
    async getAvailableClouds(): Promise<string[]> {
        const endpoint = '/api/features/names';
        const url = this.getApiUrl(endpoint);

        try {
            return await this.apiService.get<string[]>(url);
        } catch (error) {
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Retrieves a list of features for a specific rollout ID.
     *
     * @param rolloutId - The ID of the rollout to retrieve features for.
     * @returns The list of features for the given rollout ID.
     */
    async getRolloutFeatures(rolloutId: number): Promise<RolloutFeaturesResponse> {
        const endpoint = `/api/rollout/${rolloutId}/features`;
        const url = this.getApiUrl(endpoint);

        try {
            return await this.apiService.get<RolloutFeaturesResponse>(url);
        } catch (error) {
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }

    /**
     * Adds a new cloud feature to a rollout.
     *
     * @param cloudName - The cloud region identifier for the rollout.
     * @param data - The data containing the rollout details, feature flags, audience, and filters.
     * @returns A promise that resolves to the updated FeatureFlightRollout object.
     * @throws If an error occurs during the API call or handling the error response.
     */
    async addCloudToRollout(cloudName: string, data: AddCloudToRollout): Promise<FeatureFlightRollout> {
        const endpoint = `api/rollout/addFeatureToRollout`;
        const url = this.getApiUrl(endpoint);

        const payload = {
            ...data,
            cloud: cloudName
        };

        try {
            return await this.apiService.post<FeatureFlightRollout>(url, payload);
        } catch (error) {
            this.handleApiError(error as ApiResponseError);
            throw error;
        }
    }
}

export default FeatureFlightService;
