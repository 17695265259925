import { DefaultButton, HoverCard, HoverCardType, IPlainCardProps, Link, Stack, Text, assign } from '@fluentui/react';
import React from 'react';

import { Card, CardFooter, CardHeader, ICardStyles } from '../../../components/Card';
import { FeatureFlightRings } from '../configs/defaults';
import { boldText, gapStackTokensMedium, gapStackTokensSmall, hoverText } from '../styles/FFv2Style';
import { flightStatusCardStyleMap, flightStatusCardStyles } from '../styles/FlightInfoStyle';
import { FlightRequest, RollbackOption } from '../types/flightRequest.types';
import { FlightUpdate, FlightUpdateType, ProcessedFlightRollout } from '../types/Types';
import { capitalizeFirstLetter } from '../utilities/FFv2Utils';

import FlightRequestPanel from './FlightRequestPanel';

type FlightUpdatesProps = {
    flight: ProcessedFlightRollout;
    updates: FlightUpdate[];
    flightRequests: Record<string, FlightRequest>;
    fetchFlight: () => void;
};

/**
 * Displays essential flight update info.
 *
 * @param props The props for the component.
 * @returns The flight updates.
 */
const FlightUpdates: React.FC<FlightUpdatesProps> = (props) => {
    const sortedUpdates = props.updates.sort((a, b) => {
        return a.timestamp - b.timestamp;
    });

    const [flightRequest, setFlightRequest] = React.useState<FlightRequest | undefined>();

    return (
        <>
            <FlightRequestPanel
                setFlightRequest={setFlightRequest}
                flightRequest={flightRequest}
                fetchFlight={props.fetchFlight}
                flight={props.flight}
            />
            <Card>
                <CardHeader>Flight updates</CardHeader>
            </Card>
            <Stack horizontal tokens={gapStackTokensMedium} styles={{ root: { padding: '5px' } }} wrap={true}>
                {sortedUpdates.map((update, index) => updateInfoCard(update, index))}
            </Stack>
        </>
    );

    function updateInfoCard(update: FlightUpdate, index: number) {
        if (
            !update ||
            update.updateType === 'notification' ||
            update.updateType === 'checkRolloutBlockers' ||
            update.updateType === 'createConfigReminderWorkItem'
        ) {
            return;
        }

        const status =
            update.updateType === FlightUpdateType.allocationUpdate
                ? update.updateValue.request?.allocationPercentage === 100
                    ? 'Completed'
                    : 'Active'
                : update.updateType === FlightUpdateType.updateRolloutState || update.updateType === FlightUpdateType.updateExperimentState
                  ? update.updateValue.request?.state === 'Stopped'
                      ? 'Blocked'
                      : 'Active'
                  : update.updateType === FlightUpdateType.createRolloutBlocker
                    ? 'Blocked'
                    : update.updateType === FlightUpdateType.updateReleaseApproval
                      ? update.updateValue.response?.status === 'rejected'
                          ? 'Blocked'
                          : 'Completed'
                      : 'Active';

        const mergeStyles: ICardStyles = {
            root: assign({ minHeight: '100px', width: '17.5%' }, flightStatusCardStyles.root, flightStatusCardStyleMap[status].root),
            body: assign(flightStatusCardStyles.body, flightStatusCardStyleMap[status].body),
            footer: flightStatusCardStyles.footer
        };

        const headerText =
            update.updateType === FlightUpdateType.updateRolloutState
                ? 'Rollout state updated'
                : update.updateType === FlightUpdateType.allocationUpdate
                  ? 'Allocation updated'
                  : update.updateType === FlightUpdateType.updateExperimentState
                    ? 'Experiment state updated'
                    : update.updateType === FlightUpdateType.createRolloutBlocker
                      ? 'Flight blocker created'
                      : update.updateType === FlightUpdateType.updateReleaseApproval
                        ? 'Release approval updated'
                        : update.updateType === FlightUpdateType.editRollout
                          ? 'Rollout edited'
                          : update.updateType === FlightUpdateType.queueBuild
                            ? `Test build for ${update.updateValue.request.ring} queued`
                            : update.updateType === FlightUpdateType.createFlightRequest
                              ? `${capitalizeFirstLetter(update.updateValue.request.type as unknown as FlightUpdateType)} requested`
                              : update.updateType === FlightUpdateType.rollback
                                ? 'Rollback Completed'
                                : 'Unknown update';

        const editHistoryCard = () => {
            if (!Array.isArray(update.updateValue.request)) return <></>;
            // legacy data
            if (!update.updateValue.request[0].new || !update.updateValue.request[0].key) {
                return <pre>{JSON.stringify(update.updateValue.request)}</pre>;
            }
            return (
                <table cellSpacing={10}>
                    <tbody>
                        <tr>
                            <td>
                                <Text styles={boldText}>Field Name</Text>
                            </td>
                            <td>
                                <Text styles={boldText}>Old</Text>
                            </td>
                            <td>
                                <Text styles={boldText}>New</Text>
                            </td>
                        </tr>
                        {update.updateValue.request.map((row) => {
                            if (row.key !== 'filters') {
                                return (
                                    <tr key={`${update.updateType}-${index}-${row.key}`}>
                                        <td>
                                            <Text>{row.key}</Text>
                                        </td>
                                        <td>
                                            <Text>
                                                {row.old ? (
                                                    typeof row.old === 'string' ? (
                                                        row.old
                                                    ) : (
                                                        JSON.stringify(row.old)
                                                    )
                                                ) : (
                                                    <i>(undefined)</i>
                                                )}
                                            </Text>
                                        </td>
                                        <td>
                                            <Text>{typeof row.new === 'string' ? row.new : JSON.stringify(row.new)}</Text>
                                        </td>
                                    </tr>
                                );
                            } else {
                                const allFilterKeys = [...new Set(Object.keys(row.new).concat(Object.keys(row.old)))];
                                return (
                                    <>
                                        {allFilterKeys.map((filterKey) => {
                                            if (
                                                !Object.keys(row.new).includes(filterKey) ||
                                                !Object.keys(row.old).includes(filterKey) ||
                                                row.old[filterKey] !== row.new[filterKey]
                                            ) {
                                                return (
                                                    <tr key={`${update.updateType}-${index}-${filterKey}`}>
                                                        <td>
                                                            <Text>{filterKey}</Text>
                                                        </td>
                                                        <td>
                                                            <Text>
                                                                {Object.keys(row.old).includes(filterKey)
                                                                    ? typeof row.old[filterKey] === 'string'
                                                                        ? row.old[filterKey]
                                                                        : JSON.stringify(row.old[filterKey])
                                                                    : '(empty)'}
                                                            </Text>
                                                        </td>
                                                        <td>
                                                            <Text>
                                                                {Object.keys(row.new).includes(filterKey)
                                                                    ? typeof row.new[filterKey] === 'string'
                                                                        ? row.new[filterKey]
                                                                        : JSON.stringify(row.new[filterKey])
                                                                    : '(empty)'}
                                                            </Text>
                                                        </td>
                                                    </tr>
                                                );
                                            } else {
                                                return <></>;
                                            }
                                        })}
                                    </>
                                );
                            }
                        })}
                    </tbody>
                </table>
            );
        };

        const plainCardProps: IPlainCardProps = {
            onRenderPlainCard: editHistoryCard,
            renderData: update
        };

        if (
            update.updateType === FlightUpdateType.editRollout &&
            (!Array.isArray(update.updateValue.request) || update.updateValue.request.length === 0)
        ) {
            return <></>;
        }

        return (
            <Card styles={mergeStyles} key={index}>
                <CardHeader>{headerText}</CardHeader>
                <div>
                    {update.updateType === FlightUpdateType.updateRolloutState && <Text>{update.updateValue.response?.state}</Text>}
                    {update.updateType === FlightUpdateType.allocationUpdate && !update.updateValue.request.experimentId && (
                        <Text>{update.updateValue.request?.stageName}</Text>
                    )}
                    {update.updateType === FlightUpdateType.allocationUpdate && update.updateValue.request.experimentId && (
                        <Text>
                            {capitalizeFirstLetter(
                                update.updateValue.response?.filters?.find((filter) => filter.name === 'TeamsRing')?.value || ''
                            )}
                            {update.updateValue.request?.allocationPercentage}%
                        </Text>
                    )}
                    {update.updateType === FlightUpdateType.updateExperimentState && <Text>{update.updateValue.request?.state}</Text>}
                    {update.updateType === FlightUpdateType.createRolloutBlocker && (
                        <Stack tokens={gapStackTokensSmall}>
                            <Text>
                                <Link href={update.updateValue.response?.url} target="_blank">
                                    {update.updateValue.response.id}
                                </Link>
                                {' - '}
                                {update.updateValue.response.title}
                            </Text>
                            {update.updateValue.request.ring && (
                                <Text>
                                    Blocked ring - {FeatureFlightRings.find((ring) => ring.ring === update.updateValue.request?.ring)?.text}
                                </Text>
                            )}
                            <br />
                        </Stack>
                    )}
                    {update.updateType === FlightUpdateType.updateReleaseApproval && (
                        <Stack tokens={gapStackTokensSmall}>
                            <Text>
                                {capitalizeFirstLetter(update.updateValue.response?.status || '')} by{' '}
                                {update.updateValue.response?.approvedBy?.displayName}
                            </Text>
                        </Stack>
                    )}
                    {update.updateType === FlightUpdateType.editRollout && (
                        <Stack tokens={gapStackTokensSmall}>
                            <HoverCard type={HoverCardType.plain} plainCardProps={plainCardProps} instantOpenOnClick={true}>
                                <Text styles={hoverText}>details</Text>
                            </HoverCard>
                        </Stack>
                    )}
                    {update.updateType === FlightUpdateType.queueBuild && (
                        <Stack tokens={gapStackTokensSmall}>
                            <Link
                                href={`https://domoreexp.visualstudio.com/Teamspace/_build/results?buildId=${update.updateValue.response.buildId}&view=results`}
                                target="_blank"
                            >
                                {update.updateValue.response.buildId as string}
                            </Link>
                        </Stack>
                    )}
                    {update.updateType === FlightUpdateType.createFlightRequest && (
                        <Stack tokens={gapStackTokensSmall}>
                            <Text>
                                {update.updateValue.request.options.rollbackOption === RollbackOption.revert
                                    ? `Revert from ${update.updateValue.request.options.ring ?? 'ring ??'}`
                                    : RollbackOption.rollback
                                      ? `Rollback to ${update.updateValue.request.options.ring ?? 'ring ??'}`
                                      : update.updateValue.request.options.rollbackOption}
                                {update.updateValue.response.id
                                    ? ` (${props.flightRequests[update.updateValue.response.id]?.status ?? 'unknown'})`
                                    : ''}
                            </Text>
                            {update.updateValue.response.id && props.flightRequests[update.updateValue.response.id] && (
                                <DefaultButton
                                    text="Open request details"
                                    onClick={() => {
                                        if (update.updateValue.response.id) {
                                            setFlightRequest(props.flightRequests[update.updateValue.response.id]);
                                        }
                                    }}
                                />
                            )}
                        </Stack>
                    )}
                    {update.updateType === FlightUpdateType.rollback && (
                        <Stack tokens={gapStackTokensSmall}>
                            <Text>Environment: {update.updateValue.request.currentState?.environment}</Text>
                            <Text>
                                {update.updateValue.request.currentState?.ring ?? 'ring ??'} -{'>'}{' '}
                                {update.updateValue.response.newState?.ring ?? 'ring ??'}
                            </Text>
                        </Stack>
                    )}
                </div>
                <CardFooter>{new Date(update.timestamp).toLocaleString()}</CardFooter>
            </Card>
        );
    }
};

export default FlightUpdates;
