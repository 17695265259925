import { createContext } from 'react';

import { GroupInfo, UserInfo } from '../types/Types';

const UserContext = createContext<UserContextType>({
    securityGroups: [],
    directReports: [],
    peers: []
});

export type UserContextType = {
    securityGroups: GroupInfo[];
    directReports: UserInfo[];
    peers: UserInfo[];
    manager?: UserInfo;
    skip?: UserInfo;
};

export default UserContext;
