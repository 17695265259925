import {
    Stack,
    CompoundButton,
    IIconProps,
    IButtonStyles,
    IColumn,
    DetailsList,
    DetailsListLayoutMode,
    MessageBar,
    MessageBarType,
    Icon,
    PrimaryButton,
    DefaultButton,
    TooltipHost
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useState } from 'react';
import * as XLSX from 'xlsx';

import { gapStackTokensMedium } from '../styles/FFv2Style';
import { ProcessedFlightRollout } from '../types/Types';

import AddNewCloudForm from './AddNewCloudForm';

type AdminToolsTableProps = {
    ffv2FlightData: ProcessedFlightRollout[] | undefined;
};

/**
 * The AdminToolsTable component renders a control panel for the admin.
 *
 * @param props The props for the component.
 * @returns {React.FC} The AdminToolsTable component.
 */
const AdminToolsTable: React.FC<AdminToolsTableProps> = (props) => {
    const { ffv2FlightData } = props;
    const [isAddNewCloudModalOpen, { setTrue: showAddNewCloudModal, setFalse: hideAddNewCloudModal }] = useBoolean(false);
    const [results, setResults] = useState<Array<{ flightId: number; flightName: string; cloud: string; message: string; icon?: string }>>(
        []
    );
    const [selectedCloud, setSelectedCloud] = useState<string | undefined>();
    const [resultMsg, setResultMsg] = useState<string | null>(null);

    const cloudIcon: IIconProps = { iconName: 'Cloud' };
    const buttonStyles: IButtonStyles = {
        root: {
            width: '210px',
            textAlign: 'center'
        }
    };

    const columns: IColumn[] = [
        { key: 'flightId', name: 'Flight ID', fieldName: 'flightId', minWidth: 100, maxWidth: 150, isResizable: true },
        { key: 'flightName', name: 'Flight Name', fieldName: 'flightName', minWidth: 100, maxWidth: 150, isResizable: true },
        { key: 'cloud', name: 'New Cloud', fieldName: 'cloud', minWidth: 100, maxWidth: 150, isResizable: true },
        {
            key: 'message',
            name: 'Message',
            fieldName: 'message',
            minWidth: 200,
            maxWidth: 400,
            isResizable: true,
            isMultiline: true,
            onRender: (item: { message: string; icon?: string }) => {
                const iconStyles = {
                    color: item.icon === 'ErrorBadge' ? 'red' : item.icon === 'CheckMark' ? 'green' : 'inherit'
                };
                return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        {item.icon && <Icon iconName={item.icon} style={iconStyles} />}
                        <span>{item.message}</span>
                    </div>
                );
            }
        }
    ];

    const handlAddNewCloudClicked = () => {
        setResults([]);
        setResultMsg('');
        showAddNewCloudModal();
    };

    const handleResultsUpdate = (
        updatedResults: Array<{ flightId: number; flightName: string; cloud: string; message: string; icon?: string }>
    ) => {
        setResults((prevResults) => [...prevResults, ...updatedResults]);
    };

    const handleClearResults = () => {
        setResults([]);
        setResultMsg('');
    };

    const handleExportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(results);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Results');
        XLSX.writeFile(workbook, 'AddNewCloudresults.xlsx');
    };

    return (
        <Stack style={{ padding: '10px' }}>
            <CompoundButton
                primary
                secondaryText="Add new cloud to all flights"
                iconProps={cloudIcon}
                onClick={handlAddNewCloudClicked}
                styles={buttonStyles}
            >
                Add New Cloud
            </CompoundButton>

            <Stack>
                {resultMsg && (
                    <MessageBar delayedRender={false} messageBarType={MessageBarType.info} styles={{ root: { marginTop: '10px' } }}>
                        {resultMsg}
                    </MessageBar>
                )}
            </Stack>

            {isAddNewCloudModalOpen && (
                <AddNewCloudForm
                    isOpen={isAddNewCloudModalOpen}
                    flightData={ffv2FlightData}
                    onDismiss={hideAddNewCloudModal}
                    onResultsUpdate={handleResultsUpdate}
                    selectedCloud={selectedCloud}
                    setSelectedCloud={setSelectedCloud}
                    setResultMsg={setResultMsg}
                />
            )}
            {results.length > 0 && (
                <Stack tokens={{ padding: 20 }}>
                    <h4>Update Results</h4>

                    <Stack horizontal tokens={gapStackTokensMedium}>
                        <TooltipHost content="Clear the results list" id="clearTooltip">
                            <PrimaryButton
                                text="Clear"
                                onClick={handleClearResults}
                                iconProps={{ iconName: 'Delete' }}
                                aria-describedby="clearTooltip"
                            />
                        </TooltipHost>

                        {/* Export Button */}
                        <TooltipHost content="Export results to an Excel file" id="exportTooltip">
                            <DefaultButton
                                text="Export"
                                onClick={handleExportToExcel}
                                iconProps={{ iconName: 'ExcelDocument' }}
                                aria-describedby="exportTooltip"
                            />
                        </TooltipHost>
                    </Stack>

                    <DetailsList
                        items={results}
                        columns={columns}
                        setKey="set"
                        layoutMode={DetailsListLayoutMode.justified}
                        selectionMode={0}
                    />
                </Stack>
            )}
        </Stack>
    );
};

export default AdminToolsTable;
