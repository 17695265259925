import {
    IconButton,
    Dropdown,
    IDropdownOption,
    Modal,
    PrimaryButton,
    MessageBar,
    MessageBarType,
    ProgressIndicator,
    TextField,
    Stack
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import React, { useEffect, useState } from 'react';

import FeatureFlightService from '../../../services/featureFlight.service';
import { appInsightsClient } from '../../../utils/appInsightsUtility';
import { audienceNames } from '../configs/defaults';
import { gapStackTokensMedium, gapStackTokensSmall, progressIndicatorCustomStyles } from '../styles/FFv2Style';
import { formStyles, iconButtonStyles } from '../styles/MyFlightsStyle';
import { FlightRequest, FlightRequestType, RollbackOption } from '../types/flightRequest.types';
import { ProcessedFlightRollout } from '../types/Types';

type RollbackFormProps = {
    isOpen: boolean;
    onDismiss: () => void;
    selectedFlight: ProcessedFlightRollout | undefined;
    updateFlight: (flightId: string) => void;
    defaultOperation: RollbackOption;
};

/**
 * Displays the form to fill for a new flight request.
 *
 * @param props The props for the component.
 * @returns The flight request form.
 */
const RollbackForm: React.FC<RollbackFormProps> = (props) => {
    const { selectedFlight: flight, isOpen } = props;
    const [formData, setFormData] = useState(getDefaultFormData());
    const [requestResult, setRequestResult] = useState<FlightRequest | Error | 'loading' | undefined>();

    const titleId = useId('title');
    const cancelIcon = { iconName: 'Cancel' };

    const isSubmitEnabled = formData.options.rollbackOption;
    const rollbackOptions: IDropdownOption[] = [
        { key: RollbackOption.revert, text: 'Revert from' },
        { key: RollbackOption.rollback, text: 'Rollback to' }
    ];
    const ringOptions: IDropdownOption[] =
        formData.options.rollbackOption === RollbackOption.rollback
            ? [{ key: 'ring0', text: 'Ring 0' }]
            : flight?.audience === audienceNames.enterprise && ['ring3', 'ring3_6'].includes(flight?.state.ring)
              ? [
                    {
                        key: 'ring3',
                        text: 'Ring 3'
                    },
                    { key: 'ring3_6', text: 'Ring 3.6' }
                ]
              : [{ key: flight?.state.ring ?? '', text: `Ring ${flight?.state.ring[4]}` }];

    const handleDismiss = () => {
        setFormData(getDefaultFormData());
        props.onDismiss();
    };

    useEffect(() => {
        setFormData(getDefaultFormData());
    }, [isOpen]);

    return (
        <>
            {requestResult &&
                requestResult !== 'loading' &&
                ('message' in requestResult ? (
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        onDismiss={() => {
                            setRequestResult(undefined);
                            setFormData(getDefaultFormData());
                        }}
                    >
                        Rollback request failed: {requestResult.message}
                    </MessageBar>
                ) : (
                    <MessageBar
                        messageBarType={MessageBarType.success}
                        onDismiss={() => {
                            setRequestResult(undefined);
                            setFormData(getDefaultFormData());
                        }}
                    >
                        Rollback request for flight {requestResult.flightId} created successfully.
                    </MessageBar>
                ))}
            {requestResult === 'loading' && <ProgressIndicator label="Submitting request..." styles={progressIndicatorCustomStyles} />}
            <Modal titleAriaId={titleId} isOpen={isOpen && flight !== undefined} onDismiss={handleDismiss} isBlocking={false}>
                <div className={formStyles.container}>
                    <div className={formStyles.header}>
                        <span id={titleId}>New Rollback Request</span>
                        <IconButton
                            styles={iconButtonStyles}
                            iconProps={cancelIcon}
                            ariaLabel="Close popup modal"
                            onClick={handleDismiss}
                        />
                    </div>
                    <div className={formStyles.body}>
                        <Stack tokens={gapStackTokensMedium}>
                            <form>
                                <Stack tokens={gapStackTokensSmall}>
                                    <Stack.Item>
                                        <Dropdown
                                            label="Rollback Option"
                                            selectedKey={formData.options.rollbackOption}
                                            onChange={(e, option) => {
                                                setFormData({
                                                    ...formData,
                                                    options: { ...formData.options, rollbackOption: option?.key as RollbackOption }
                                                });
                                            }}
                                            options={rollbackOptions}
                                            disabled
                                            required
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Dropdown
                                            label="Ring"
                                            selectedKey={formData.options.ring}
                                            onChange={(e, option) => {
                                                setFormData({
                                                    ...formData,
                                                    options: { ...formData.options, ring: option?.key as string }
                                                });
                                            }}
                                            options={ringOptions}
                                            required
                                        />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <TextField
                                            label="Comment"
                                            value={formData.comment ?? ''}
                                            onChange={(e, newValue) => {
                                                setFormData({ ...formData, comment: newValue });
                                            }}
                                        />
                                    </Stack.Item>

                                    <Stack.Item>
                                        <br />
                                        <PrimaryButton text="Submit" onClick={onSubmit} allowDisabledFocus disabled={!isSubmitEnabled}>
                                            Submit
                                        </PrimaryButton>
                                    </Stack.Item>
                                </Stack>
                            </form>
                        </Stack>
                    </div>
                </div>
            </Modal>
        </>
    );

    function onSubmit() {
        if (!flight) return;

        appInsightsClient.logEvent({ name: 'RollbackRequestFormSubmit', properties: { response: 'good' } });
        setRequestResult('loading');
        props.onDismiss();

        new FeatureFlightService()
            .createFlightRequest(flight.id, formData)
            .then((response) => {
                setRequestResult(response);
                appInsightsClient.logEvent({ name: 'RollbackRequestFormSubmit', properties: { response: response } });
                props.updateFlight(flight.id);
            })
            .catch((fail) => {
                setRequestResult(fail);
                appInsightsClient.logException(
                    { exception: fail },
                    { message: 'caught error in creating flight request in RollbackRequestForm' }
                );
            });
        setFormData(getDefaultFormData());
    }

    function getDefaultFormData(): RollbackFormData {
        return {
            type: FlightRequestType.Rollback,
            options: {
                rollbackOption: props.defaultOperation,
                ring: props.defaultOperation === RollbackOption.revert ? flight?.state.ring ?? '' : 'ring0'
            }
        };
    }
};

export type RollbackFormData = {
    type: FlightRequestType.Rollback;
    options: {
        rollbackOption?: RollbackOption;
        ring: string;
    };
    comment?: string;
};

export default RollbackForm;
