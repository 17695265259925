import { Stack } from '@fluentui/react';
import { useConst } from '@fluentui/react-hooks';
import React, { useEffect, useState, useCallback, useMemo } from 'react';

import { Card } from '../../components/Card';
import ErrorNotification from '../../components/ErrorNotification/ErrorNotification';
import FeatureFlightService from '../../services/featureFlight.service';
import { appInsightsClient } from '../../utils/appInsightsUtility';
import { getUserInfo } from '../../utils/userUtility';

import AdminFlightCommandBar from './AdminFlightsComponents/AdminFlightsCommandBar';
import AdminToolsTable from './AdminFlightsComponents/AdminToolsTable';
import { defaultEmptyFilters, defaultTab, flightStatusValues } from './configs/defaults';
import FlightTable from './FlightsComponents/FlightTable';
import FlightTabs from './FlightsComponents/FlightTabs';
import { gapStackTokensMedium } from './styles/FFv2Style';
import {
    FeatureFlightRollout,
    FlightStatusFilter,
    ProcessedFlightRollout,
    CheckBlockersResponse,
    FlightsFiltered,
    FlightsCache,
    FlightFilters
} from './types/Types';
import { cacheData, cmpState, filterFlights, filterFlightsByStatus, getCachedData, processFlights } from './utilities/FFv2Utils';

/**
 * Displays the my flights table for the selected user.
 *
 * @returns The main table component for the my flights tab.
 */
const AdminFlights: React.FC = () => {
    const currentUser = useConst(getUserInfo());

    const [error, setError] = useState<Error | undefined>();
    const [data, setData] = useState<ProcessedFlightRollout[] | undefined>();
    const [selectedFlight, setSelectedFlight] = useState<ProcessedFlightRollout | undefined>();
    const [flightStatusFilter, setFlightStatusFilter] = useState<FlightStatusFilter>(defaultTab);
    const [flightFilters, setFlightFilters] = useState<FlightFilters>(defaultEmptyFilters);
    const [selectedTab, setSelectedTab] = useState<string>('all');

    const categorizedFlights = useMemo(() => {
        if (!data) return undefined;
        const result: FlightsFiltered = {};

        flightStatusValues.forEach((tab) => {
            result[tab.key] = filterFlightsByStatus(data, tab.key, currentUser.id);
        });
        return result;
    }, [data]);

    const flightStages = useMemo(
        () =>
            data
                ? [
                      ...new Set(
                          [...new Set(data.map((flight) => flight.state))]
                              .sort((x, y) => cmpState(x, y, false))
                              .map((state) => state.stageName)
                      )
                  ]
                : [],
        [data]
    );

    const otherUsers = useMemo(
        () =>
            data
                ? data
                      .map((flight) => flight.createdBy)
                      .filter((v, i, a) => v.id !== currentUser.id && a.findIndex((v2) => v2.id === v.id) === i)
                      .sort((x, y) => x.displayName.localeCompare(y.displayName))
                : [],
        [data]
    );

    const filteredFlights = useMemo(
        () => (categorizedFlights ? filterFlights(categorizedFlights?.[flightStatusFilter.key], flightFilters) : categorizedFlights),
        [categorizedFlights, flightFilters, flightStatusFilter]
    );

    const handleFilterChange = useCallback(
        (key: string, value: string | string[] | Date | undefined | boolean) => {
            setFlightFilters((filters) => ({ ...filters, [key]: value }));
        },
        [setFlightFilters]
    );

    const clearFilters = useCallback(() => {
        setFlightFilters(defaultEmptyFilters);
    }, [setFlightFilters]);

    useEffect(() => {
        const cache = getCachedData('ffv2FlightData') as FlightsCache | undefined;
        if (!cache) {
            fetchData();
        } else {
            setData(cache.data);
        }
    }, []);

    const switchFlightStatusFilter = useCallback((filter: FlightStatusFilter) => {
        setFlightStatusFilter(filter);
    }, []);

    const updateFlight = (flight: ProcessedFlightRollout) => {
        const newData = data?.map((item) => {
            return item.id === flight.id ? flight : item;
        });
        setData(newData);
        cacheData('ffv2FlightData', data);
    };

    const handleRefreshData = useCallback(() => {
        setData(undefined);
        fetchData();
    }, []);

    const getBlockers = useCallback(
        (flightIds: number[], curData?: ProcessedFlightRollout[]) => {
            if (flightIds.length === 0) return;
            if (!curData) curData = data;
            new FeatureFlightService()
                .getRolloutBlockers(flightIds)
                .then((response: CheckBlockersResponse) => {
                    curData = curData?.map((flight) => {
                        if (flightIds.includes(flight.rolloutId)) {
                            flight.blockers = response[flight.rolloutId];
                        }
                        return flight;
                    });
                    setData(curData);
                    appInsightsClient.logTrace(
                        { message: `Successfully fetched blockers for flights: ${flightIds.join(',')}` },
                        { user: currentUser.principalName }
                    );
                    cacheData('ffv2FlightData', curData);
                })
                .catch((fail) => {
                    console.error(`Caught error checking blockers for flights: ${flightIds.join(',')}, error: ${fail}`);
                    appInsightsClient.logException(
                        { exception: fail },
                        {
                            message: `Caught error in checkFlightBlockers in MyFlights`,
                            user: currentUser.principalName
                        }
                    );
                });
        },
        [data]
    );

    const handleTabClick = (itemKey: string) => {
        setSelectedTab(itemKey);
    };

    return (
        <>
            <Stack tokens={gapStackTokensMedium}>
                <FlightTabs
                    switchFlightStatusFilter={switchFlightStatusFilter}
                    flights={categorizedFlights}
                    isAdminView={true}
                    onAdminToolsClick={() => handleTabClick('admin-tools')}
                    selectedTab={selectedTab}
                    onTabClick={handleTabClick}
                />
                <Card>
                    {selectedTab === 'admin-tools' ? (
                        <AdminToolsTable ffv2FlightData={data} />
                    ) : (
                        <>
                            <AdminFlightCommandBar
                                flightFilters={flightFilters}
                                flightStages={flightStages}
                                flightStatusFilter={flightStatusFilter}
                                selectedFlight={selectedFlight}
                                updateFlight={updateFlight}
                                handleRefreshData={handleRefreshData}
                                otherUsers={otherUsers}
                                handleFilterChange={handleFilterChange}
                                clearFilters={clearFilters}
                            />
                            {error ? (
                                <ErrorNotification msg={`Unable to fetch feature flights.`} />
                            ) : (
                                <FlightTable
                                    flights={filteredFlights}
                                    flightStatusFilter={flightStatusFilter}
                                    setSelectedFlight={setSelectedFlight}
                                />
                            )}
                        </>
                    )}
                </Card>
            </Stack>
        </>
    );

    function fetchData(): void {
        localStorage.removeItem('ffv2FlightData');
        localStorage.removeItem('ffv2WorkItems');
        new FeatureFlightService()
            .getAllRollouts()
            .then((response: FeatureFlightRollout[]) => {
                const flights = processFlights(response);
                setData(flights);
                setError(undefined);
                cacheData('ffv2FlightData', flights);
                appInsightsClient.logTrace(
                    { message: `Successfully fetched feature flights as requested by ${currentUser.principalName}` },
                    { user: currentUser.principalName }
                );
                getBlockers(
                    flights.map((flight) => flight.rolloutId),
                    flights
                );
            })
            .catch((fail) => {
                setError(fail);
                console.error(`Caught error fetching all rollouts, error: ${fail}`);
                appInsightsClient.logException(
                    { exception: fail },
                    {
                        message: `Caught error in useEffect getRollouts in MyFlights`,
                        user: currentUser.principalName
                    }
                );
            });
    }
};

export default AdminFlights;
