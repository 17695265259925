import { Icon, Pivot, PivotItem, Link, Stack, Text, MessageBar, MessageBarType } from '@fluentui/react';
import React, { useEffect, useState } from 'react';

import { Card, CardHeader } from '../../components/Card';
import { teamsClientRelease, teamsEcsContributors, teamsFFv2SpecialUsersAccess } from '../../services/configs/msGraph.defaults';
import FeatureFlightService from '../../services/featureFlight.service';
import { appInsightsClient } from '../../utils/appInsightsUtility';
import { getUserPrincipalName, getUserContext } from '../../utils/userUtility';

import AdminFlights from './AdminFlights';
import { featureBlockerWorkflowWikiLink, safeChangeManagementWikiLink } from './configs/defaults';
import FFv2Context, { FFv2ContextType } from './contexts/FFv2Context';
import UserContext, { UserContextType } from './contexts/UserContext';
import FeedbackForm from './FeedbackForm';
import StartFlightForm from './StartFlightForm';
import { gapStackTokensMedium, mediumIconClass } from './styles/FFv2Style';
import UserFlights from './UserFlights';

/**
 * This component is to provide implementation for FFv2.
 *
 * @returns The actual view.
 */
const FFv2: React.FC = () => {
    const userPrincipalName = getUserPrincipalName();

    const [activeTab, setActiveTab] = useState('flights');
    const [userContext, setUserContext] = useState<UserContextType>({
        securityGroups: [],
        directReports: [],
        peers: []
    });
    const [ffv2Context, setFFv2Context] = useState<FFv2ContextType>({
        freezeState: ''
    });
    const isTeamsClientRelease = userContext.securityGroups.some((group) => group.displayName === teamsClientRelease);
    const isTeamsEcsContributor = userContext.securityGroups.some((group) => group.displayName === teamsEcsContributors);
    const isTeamsFFv2SpecialUsersAccess = userContext.securityGroups.some((group) => group.displayName === teamsFFv2SpecialUsersAccess);

    const handleTabChange = (item?: PivotItem) => {
        if (item) {
            setActiveTab(item.props.itemKey || '');
        }
    };

    const setFreezeState = (state: string) => {
        setFFv2Context({ ...ffv2Context, freezeState: state });
    };

    useEffect(() => {
        getUserContext()
            .then((context) => setUserContext(context))
            .catch((fail) => {
                console.error(`Caught error getting user context, error: ${fail}`);
                appInsightsClient.logException(
                    { exception: fail },
                    {
                        message: `Caught error in useEffect getUserContext in Feature Flight page`,
                        user: userPrincipalName
                    }
                );
            });

        new FeatureFlightService()
            .getFreeze()
            .then((response) => {
                setFreezeState(response.state);
            })
            .catch((fail) => {
                console.error(`Caught error getting freeze state, error: ${fail}`);
                appInsightsClient.logException(
                    { exception: fail },
                    {
                        message: `Caught error in useEffect getFreeze in Feature Flight page`,
                        user: userPrincipalName,
                        group: teamsClientRelease
                    }
                );
            });
    }, []);

    return (
        <Stack tokens={gapStackTokensMedium}>
            <Card>
                <CardHeader>
                    Feature Flight Center <Text variant="small">(MVP)</Text>
                </CardHeader>
            </Card>

            <Card>
                <CardHeader>Note(s) :</CardHeader>
                <Text variant="medium">Feature Flight V2 empowers you to:</Text>
                <ul>
                    <li>
                        <Text variant="medium">
                            Start automated rollout (flight) of feature flags in a compliant manner, following all
                            <Link href={safeChangeManagementWikiLink} target="_blank" rel="noreferrer">
                                {' '}
                                safe change management policies
                            </Link>{' '}
                            and integrated with experimentation scorecards.
                        </Text>
                    </li>
                    <li>
                        <Text variant="medium">Track and manage progress of all flights owned by you and your direct reports.</Text>
                    </li>
                    <li>
                        <Text variant="medium">
                            Have full control over progressing flights by raising
                            <Link href={featureBlockerWorkflowWikiLink} target="_blank" rel="noreferrer">
                                {' '}
                                Flight Blockers
                            </Link>{' '}
                            , ADO bugs that halt automated flights.
                        </Text>
                    </li>
                </ul>
                <Text variant="medium">
                    Check{' '}
                    <Link
                        href="https://domoreexp.visualstudio.com/Teamspace/_wiki/wikis/Teamspace.wiki/60342/Feature-Flighting-v2"
                        target="_blank"
                    >
                        Feature Flight V2 wiki
                    </Link>{' '}
                    for user manual, Q&A and more. Any suggestion or bug report? <FeedbackForm /> or post in{' '}
                    <Link
                        href="https://teams.microsoft.com/l/channel/19%3Askypespaces_60599f608e5542939a1fe4932dea4326%40thread.skype/Build%2C%20Quality%2C%20Testing%20and%20Release%20%5BBQTR%5D?groupId=af55e84c-dc67-4e48-9005-86e0b07272f9&tenantId=72f988bf-86f1-41af-91ab-2d7cd011db47"
                        target="_blank"
                    >
                        our Teams channel
                    </Link>
                    !
                </Text>
            </Card>
            {ffv2Context.freezeState && ffv2Context.freezeState !== 'Operational' && (
                <MessageBar messageBarType={MessageBarType.severeWarning}>
                    FFv2 is in the freeze state of {ffv2Context.freezeState}. You may still approve or reject flights, but they will not
                    proceed until freeze ends.
                </MessageBar>
            )}
            <UserContext.Provider value={userContext}>
                <FFv2Context.Provider value={ffv2Context}>
                    <Card>
                        <Pivot onLinkClick={handleTabChange} selectedKey={activeTab} overflowBehavior="menu">
                            <PivotItem headerText="Flights" itemKey="flights" />
                            <PivotItem headerText="Start Flight" itemKey="start-flight" />
                            {isTeamsClientRelease && <PivotItem headerText="Admin Flights" itemKey="admin-flights" />}
                        </Pivot>
                    </Card>
                    {activeTab && activeTab === 'flights' && <UserFlights />}
                    {activeTab && activeTab === 'start-flight' && (
                        <Card>
                            {isTeamsEcsContributor ? (
                                <StartFlightForm
                                    enableUserOverrides={isTeamsFFv2SpecialUsersAccess}
                                    isTeamsClientRelease={isTeamsClientRelease}
                                />
                            ) : (
                                <Stack verticalAlign="center" styles={{ root: { minHeight: '200px' } }} tokens={gapStackTokensMedium}>
                                    <Stack.Item align="center">
                                        <Icon iconName="UserWarning" className={mediumIconClass} />
                                    </Stack.Item>
                                    <Stack.Item align="center">
                                        <Text variant="large">You do not have access to start flights</Text>
                                    </Stack.Item>
                                    <Stack.Item align="center" styles={{ root: { maxWidth: '750px' } }}>
                                        <Text>
                                            You currently do not have appropriate permissions to flight a feature in Teams. These
                                            permissions require you be a member of the Teams organization in Teams Ring 0 or a partner
                                            sponsored to become a member of Teams Ring 0. Please work with your manager or Teams sponsor to
                                            gain the correct access.
                                        </Text>
                                    </Stack.Item>
                                </Stack>
                            )}
                        </Card>
                    )}
                    {activeTab && activeTab === 'admin-flights' && <AdminFlights />}
                </FFv2Context.Provider>
            </UserContext.Provider>
        </Stack>
    );
};

export default FFv2;
