import { Coachmark, DirectionalHint, IButtonProps, TeachingBubbleContent } from '@fluentui/react';
import React, { useEffect, useState, useCallback, useMemo, useContext } from 'react';

import { Card } from '../../components/Card';
import ErrorNotification from '../../components/ErrorNotification/ErrorNotification';
import FeatureFlightService from '../../services/featureFlight.service';
import { appInsightsClient } from '../../utils/appInsightsUtility';
import { getUserInfo } from '../../utils/userUtility';

import { defaultEmptyFilters, defaultTab, flightStatusValues, tutorialStepsByGroup } from './configs/defaults';
import UserContext from './contexts/UserContext';
import UserFlightsCommandBar from './FlightsComponents/FlightsCommandBar';
import FlightTable from './FlightsComponents/FlightTable';
import FlightTabs from './FlightsComponents/FlightTabs';
import {
    FeatureFlightRollout,
    FlightStatusFilter,
    ProcessedFlightRollout,
    CheckBlockersResponse,
    FlightsFiltered,
    FlightsCache,
    FlightFilters
} from './types/Types';
import {
    cacheData,
    cmpState,
    filterFlights,
    filterFlightsByStatus,
    getCachedData,
    isFlightInProgress,
    processFlights
} from './utilities/FFv2Utils';

/**
 * Displays the my flights table for the selected user.
 *
 * @returns The main table component for the my flights tab.
 */
const UserFlights: React.FC = () => {
    const userContext = useContext(UserContext);
    const currentUser = getUserInfo();
    const teamMembers = [...userContext.peers, ...userContext.directReports];
    if (userContext.manager) teamMembers.push(userContext.manager);
    if (userContext.skip) teamMembers.push(userContext.skip);
    const targetPivot = React.useRef<HTMLDivElement>(null);

    const [teachingBubbleStep, setTeachingBubbleStep] = useState<number>(0);
    const [error, setError] = useState<Error | undefined>();
    const [data, setData] = useState<ProcessedFlightRollout[] | undefined>();
    const [selectedFlight, setSelectedFlight] = useState<ProcessedFlightRollout | undefined>();
    const [flightStatusFilter, setFlightStatusFilter] = useState<FlightStatusFilter>(defaultTab);
    const [flightFilters, setFlightFilters] = useState<FlightFilters>(defaultEmptyFilters);

    const otherUsers = useMemo(
        () =>
            data
                ? data
                      .map((flight) => flight.createdBy)
                      .filter(
                          (v, i, a) =>
                              v.id !== currentUser.id &&
                              a.findIndex((v2) => v2.id === v.id) === i &&
                              teamMembers.findIndex((tm) => tm.id === v.id) === -1
                      )
                      .sort((x, y) => x.displayName.localeCompare(y.displayName))
                : [],
        [data, teamMembers]
    );

    const progressToNextStep = useCallback(() => {
        setTeachingBubbleStep((step) => step + 1);
    }, [setTeachingBubbleStep]);

    const dismissTeachingBubble = useCallback(() => {
        setTeachingBubbleStep(tutorialStepsByGroup.myFlights[1] + 1);
    }, [setTeachingBubbleStep]);

    const handleFilterChange = useCallback(
        (key: string, value: string | string[] | Date | undefined | boolean) => {
            setFlightFilters((filters) => ({ ...filters, [key]: value }));
        },
        [setFlightFilters]
    );

    const clearFilters = useCallback(() => defaultEmptyFilters, [setFlightFilters]);

    const primaryButtonProps: IButtonProps = {
        children: 'Next',
        onClick: progressToNextStep
    };

    const categorizedFlights = useMemo(() => {
        if (!data) return undefined;
        const result: FlightsFiltered = {};

        flightStatusValues.forEach((tab) => {
            result[tab.key] = filterFlightsByStatus(data, tab.key, currentUser.id);
        });
        return result;
    }, [data]);

    const flightStages = useMemo(
        () =>
            data
                ? [
                      ...new Set(
                          [...new Set(data.map((flight) => flight.state))]
                              .sort((x, y) => cmpState(x, y, false))
                              .map((state) => state.stageName)
                      )
                  ]
                : [],
        [data]
    );

    const filteredCategorizedFlights = useMemo(() => {
        if (!categorizedFlights) return undefined;
        const result: FlightsFiltered = {};
        for (const key in categorizedFlights) {
            result[key] = filterFlights(categorizedFlights[key], flightFilters, currentUser, teamMembers);
        }
        return result;
    }, [categorizedFlights, flightFilters, flightStatusFilter]);

    const filteredFlights = filteredCategorizedFlights?.[flightStatusFilter.key];

    useEffect(() => {
        const cachedFlights = getCachedData('ffv2FlightData') as FlightsCache | undefined;
        if (!cachedFlights) {
            fetchData();
        } else {
            setData(cachedFlights.data);
        }

        new FeatureFlightService()
            .getTutorialProgress('myFlights')
            .then((response) => {
                setTeachingBubbleStep(tutorialStepsByGroup.myFlights[response] + 1);
            })
            .catch((fail) => {
                console.error(`Caught error getting tutorial progress, error: ${fail}`);
                appInsightsClient.logException(
                    { exception: fail },
                    {
                        message: `Caught error in useEffect getTutorialProgress in My Flights page`,
                        user: currentUser.principalName
                    }
                );
            });
    }, []);

    useEffect(() => {
        for (const groupIndex of tutorialStepsByGroup.myFlights) {
            if (teachingBubbleStep === groupIndex + 1) {
                new FeatureFlightService().setTutorialProgress('myFlights', groupIndex).catch((fail) => {
                    console.error(`Caught error setting tutorial progress, error: ${fail}`);
                    appInsightsClient.logException(
                        { exception: fail },
                        {
                            message: `Caught error in useEffect setTutorialProgress in My Flights page`
                        }
                    );
                });
            }
        }
    }, [teachingBubbleStep]);

    const switchFlightStatusFilter = useCallback((filter: FlightStatusFilter) => {
        setFlightStatusFilter(filter);
    }, []);

    const updateFlight = (flightId: string) => {
        new FeatureFlightService()
            .getRolloutsById(flightId)
            .then((response: FeatureFlightRollout[]) => {
                const flight = processFlights(response)[0];
                const newData = data?.map((item) => {
                    return item.id === flightId ? flight : item;
                });
                setData(newData);
                appInsightsClient.logTrace(
                    { message: `Successfully fetched feature flight ${flightId}` },
                    { user: currentUser.principalName }
                );
                cacheData('ffv2FlightData', newData);
            })
            .catch((fail) => {
                console.error(`Caught error fetching flight: ${flightId}, error: ${fail}`);
                appInsightsClient.logException(
                    { exception: fail },
                    {
                        message: `Caught error in updateFlight in MyFlights`,
                        user: currentUser.principalName
                    }
                );
            });
    };

    const updateSubscribers = (flightId: string, isSubscribed: boolean) => {
        const newData = data?.map((item) => {
            return item.id === flightId ? { ...item, isSubscribed } : item;
        });
        setData(newData);
        const oldCache = getCachedData('ffv2FlightData') as FlightsCache;
        localStorage.setItem(
            'ffv2FlightData',
            JSON.stringify({
                ...oldCache,
                data: newData
            })
        );
    };

    const handleRefreshData = useCallback(() => {
        setData(undefined);
        fetchData();
    }, []);

    const getBlockers = useCallback(
        (flightIds: number[], curData?: ProcessedFlightRollout[]) => {
            if (flightIds.length === 0) return;
            if (!curData) curData = data;
            new FeatureFlightService()
                .getRolloutBlockers(flightIds)
                .then((response: CheckBlockersResponse) => {
                    curData = curData?.map((flight) => {
                        if (flightIds.includes(flight.rolloutId)) {
                            flight.blockers = response[flight.rolloutId];
                        }
                        return flight;
                    });
                    setData(curData);
                    appInsightsClient.logTrace(
                        { message: `Successfully fetched blockers for flights: ${flightIds.join(',')}` },
                        { user: currentUser.principalName }
                    );
                    cacheData('ffv2FlightData', curData);
                })
                .catch((fail) => {
                    console.error(`Caught error checking blockers for flights: ${flightIds.join(',')}, error: ${fail}`);
                    appInsightsClient.logException(
                        { exception: fail },
                        {
                            message: `Caught error in checkFlightBlockers in MyFlights`,
                            user: currentUser.principalName
                        }
                    );
                });
        },
        [data]
    );

    return (
        <>
            {error ? (
                <Card>
                    <ErrorNotification msg={`Unable to fetch feature flights.`} />
                </Card>
            ) : (
                <>
                    {teachingBubbleStep === 1 && (
                        <Coachmark
                            target={targetPivot}
                            positioningContainerProps={{
                                directionalHint: DirectionalHint.topLeftEdge,
                                doNotLayer: false
                            }}
                        >
                            <TeachingBubbleContent
                                headline="Flight status tabs"
                                hasCloseButton
                                closeButtonAriaLabel="Close"
                                onDismiss={dismissTeachingBubble}
                                primaryButtonProps={primaryButtonProps}
                                footerContent="1 of 4"
                            >
                                Explore flights categorized by their progress for easy tracking and management.
                            </TeachingBubbleContent>
                        </Coachmark>
                    )}
                    <div ref={targetPivot}>
                        <FlightTabs switchFlightStatusFilter={switchFlightStatusFilter} flights={filteredCategorizedFlights} />
                    </div>
                    <Card>
                        <UserFlightsCommandBar
                            teamMembers={teamMembers}
                            otherUsers={otherUsers}
                            flightFilters={flightFilters}
                            flightStages={flightStages}
                            flightStatusFilter={flightStatusFilter}
                            getBlockers={getBlockers}
                            selectedFlight={selectedFlight}
                            updateFlight={updateFlight}
                            handleRefreshData={handleRefreshData}
                            teachingBubbleStep={teachingBubbleStep}
                            progressToNextStep={progressToNextStep}
                            dismissTeachingBubble={dismissTeachingBubble}
                            handleFilterChange={handleFilterChange}
                            clearFilters={clearFilters}
                            updateSubscribers={updateSubscribers}
                        />
                        <FlightTable
                            flights={filteredFlights}
                            flightStatusFilter={flightStatusFilter}
                            setSelectedFlight={setSelectedFlight}
                            teachingBubbleStep={teachingBubbleStep}
                            progressToNextStep={progressToNextStep}
                            dismissTeachingBubble={dismissTeachingBubble}
                        />
                    </Card>
                </>
            )}
        </>
    );

    function fetchData(): void {
        localStorage.removeItem('ffv2FlightData');
        localStorage.removeItem('ffv2WorkItems');

        new FeatureFlightService()
            .getAllRollouts()
            .then((response: FeatureFlightRollout[]) => {
                const flights = processFlights(response);
                setData(flights);
                cacheData('ffv2FlightData', flights);
                appInsightsClient.logTrace(
                    { message: `Successfully fetched feature flights for all users` },
                    { user: currentUser.principalName }
                );
                getBlockers(
                    flights.filter((flight) => isFlightInProgress(flight)).map((flight) => flight.rolloutId),
                    flights
                );
            })
            .catch((fail) => {
                setError(fail);
                console.error(`Caught error fetching rollouts for all users, error: ${fail}`);
                appInsightsClient.logException(
                    { exception: fail },
                    {
                        message: `Caught error in useEffect getRollouts in Flights`,
                        user: currentUser.principalName
                    }
                );
            });
    }
};

export default UserFlights;
