/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */

import { ApprovalStatus, UserInfo } from './Types';

export type FlightRequest = {
    id: string;
    flightId: number;
    creator: UserInfo;
    comment?: string;
    type: FlightRequestType;
    options: {
        rollbackOption?: RollbackOption;
        ring?: string;
    };
    timestamp: number;
    status: FlightRequestStatus;
    approvals: FlightRequestApproval[];
    updates: {
        type: FlightRequestUpdateType;
        timestamp: number;
        user?: UserInfo;
        comment?: string;
        userIdentities?: string[];
    }[];
};

export enum RollbackOption {
    rollback = 'rollback',
    revert = 'revert'
}

export enum FlightRequestType {
    Rollback = 'rollback'
}

export enum FlightRequestUpdateType {
    Create = 'create',
    Approve = 'approve',
    Reject = 'reject',
    Cancel = 'cancel',
    Complete = 'complete'
}

export enum FlightRequestStatus {
    Pending = 'pending',
    Approved = 'approved',
    Rejected = 'rejected',
    Cancelled = 'cancelled',
    Completed = 'completed'
}

export type FlightRequestApprover = {
    type: FlightRequestApproverType;
    status: ApprovalStatus;
    groupName?: string;
    userPrincipalName?: string;
    user?: UserInfo;
};

export type FlightRequestApproval = {
    approvers: FlightRequestApprover[];
};

export enum FlightRequestApproverType {
    SecurityGroup = 'securityGroup',
    User = 'user',
    Manager = 'manager',
    Skip = 'skip'
}

export type FlightRequestUpdateForm = {
    type: FlightRequestUpdateType;
    userIdentities: string[];
};
