import {
    Modal,
    Stack,
    IconButton,
    Label,
    MessageBar,
    MessageBarType,
    Spinner,
    SpinnerSize,
    PrimaryButton,
    DefaultButton,
    ProgressIndicator,
    Dialog,
    DialogFooter,
    DialogType
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';

import FeatureFlightService from '../../../services/featureFlight.service';
import { appInsightsClient } from '../../../utils/appInsightsUtility';
import { audienceNames, stateStatus } from '../configs/defaults';
import { editFlightTableCellStyles } from '../styles/AdminFlightsStyle';
import { formStyles, gapStackTokensMedium, iconButtonStyles, progressIndicatorCustomStyles } from '../styles/FFv2Style';
import { cancelIcon } from '../styles/MyFlightsStyle';
import { formButtonStyles, stackItemStyles } from '../styles/StartFlightFormStyle';
import { ProcessedFlightRollout, RolloutFeaturesResponse } from '../types/Types';

import CloudsDropdown from './CloudsDropdown';

type AddNewCloudFormProps = {
    isOpen: boolean;
    flightData: ProcessedFlightRollout[] | undefined;
    onDismiss: () => void;
    onResultsUpdate: (
        updatedResults: Array<{ flightId: number; flightName: string; cloud: string; message: string; icon?: string }>
    ) => void;
    selectedCloud?: string;
    setSelectedCloud: (cloud: string | undefined) => void;
    setResultMsg: (cloud: string | null) => void;
};

/**
 * AddNewCloudForm component.
 *
 * @param props - The props for the AddNewCloudForm component.
 * @returns The AddNewCloudForm component.
 */
const AddNewCloudForm: React.FC<AddNewCloudFormProps> = (props) => {
    const { flightData, onResultsUpdate, onDismiss, setSelectedCloud, setResultMsg, selectedCloud } = props;
    const [availiableClouds, setAvailiableClouds] = useState<string[]>();
    const [loadState, setLoadState] = useState<Error | 'loading' | undefined>();
    const [processingFlightMsg, setProcessingFlightMsg] = useState<string | null>();
    const [isDialogVisible, setIsDialogVisible] = useState(false);

    useEffect(() => {
        setLoadState('loading');

        new FeatureFlightService()
            .getAvailableClouds()
            .then((response) => {
                setAvailiableClouds(response);
                setLoadState(undefined);
            })
            .catch((error) => {
                setLoadState(error);
            });
    }, []);

    async function isEligibleForNewCloudRollout(flight: ProcessedFlightRollout) {
        if (flight.audience !== audienceNames.enterprise) {
            appInsightsClient.logEvent({ name: `FFV2:AddNewCloud: Skipping flight ${flight.rolloutId}: Audience is not enterprise.` });
            const result = {
                flightId: flight.rolloutId,
                flightName: flight.rolloutName,
                cloud: selectedCloud ?? '',
                message: `Skipping flight ${flight.rolloutId}: Audience is not enterprise.`,
                icon: 'ErrorBadge'
            };
            onResultsUpdate([result]);
            return false;
        }

        if (flight.state.runningStatus !== stateStatus.running) {
            appInsightsClient.logEvent({
                name: `FFV2:AddNewCloud: Skipping flight ${flight.rolloutId}: Flight status is not Running.`
            });
            const result = {
                flightId: flight.rolloutId,
                flightName: flight.rolloutName,
                cloud: selectedCloud ?? '',
                message: `Skipping flight ${flight.rolloutId}: Flight status is not Running`,
                icon: 'ErrorBadge'
            };
            onResultsUpdate([result]);
            return false;
        }

        return await new FeatureFlightService()
            .getRolloutFeatures(flight.rolloutId)
            .then((response: RolloutFeaturesResponse) => {
                // Check if any feature's name matches the `selectedCloud`
                const isCloudAlreadyPresent = response.features.some((feature) => feature.name === selectedCloud);

                if (isCloudAlreadyPresent) {
                    appInsightsClient.logEvent({
                        name: `FFV2:AddNewCloud: Skipping flight ${flight.rolloutId}: Cloud "${selectedCloud}" is already present.`
                    });
                    const result = {
                        flightId: flight.rolloutId,
                        flightName: flight.rolloutName,
                        cloud: selectedCloud ?? '',
                        message: `Flight ${flight.rolloutId} is not eligible: Cloud "${selectedCloud}" is already present.`,
                        icon: 'ErrorBadge'
                    };
                    onResultsUpdate([result]);
                    return false;
                }

                // Check if any feature's name matches `ring4` and has `percentAllocation` of 100
                const hasRing4WithFullAllocation = response.features.some((feature) => {
                    return feature.name === 'ring4' && feature.percentAllocation === 100;
                });

                if (!hasRing4WithFullAllocation) {
                    appInsightsClient.logEvent({
                        name: `FFV2:AddNewCloud: Flight ${flight.rolloutId} is not eligible: "ring4" feature with 100% allocation was not found.`
                    });
                    const result = {
                        flightId: flight.rolloutId,
                        flightName: flight.rolloutName,
                        cloud: selectedCloud ?? '',
                        message: `Flight ${flight.rolloutId} is not eligible: "ring4" feature with 100% allocation was not found.`,
                        icon: 'ErrorBadge'
                    };
                    onResultsUpdate([result]);
                    return false;
                }

                return true;
            })
            .catch((error) => {
                appInsightsClient.logException({
                    exception: error,
                    properties: {
                        name: 'FFV2:AddNewCloudFeature',
                        message: `Error checking eligibility for flight ${flight.rolloutId}:`
                    }
                });
                return false;
            });
    }

    async function handleSubmit(): Promise<void> {
        if (!selectedCloud) {
            return;
        }

        appInsightsClient.logEvent({ name: 'FFV2:AddNewCloud:FormSubmit' });

        let successCount = 0;
        let totalFlights = 0;

        if (flightData && flightData.length > 0) {
            for (const flight of flightData) {
                totalFlights++;
                setProcessingFlightMsg(`Processing Flight with ID: ${flight.rolloutId}, Name: "${flight.rolloutName}".`);

                // Check if the flight is eligible for adding new cloud
                if (!(await isEligibleForNewCloudRollout(flight))) {
                    setProcessingFlightMsg(
                        `Flight with ID: ${flight.rolloutId}, Name: "${flight.rolloutName}" is not eligible for the new cloud update.`
                    );

                    // Wait briefly before clearing the message to show it temporarily
                    await new Promise((resolve) => setTimeout(resolve, 1000));
                    setProcessingFlightMsg(null);
                    continue;
                }

                const payload = {
                    rolloutId: flight.rolloutId,
                    featureFlags: flight.featureFlags,
                    audience: flight.audience,
                    flightType: flight.flightType,
                    filters: flight.filters,
                    allocationPercentage: 100
                };

                await new FeatureFlightService()
                    .addCloudToRollout(selectedCloud, payload)
                    .then((response) => {
                        appInsightsClient.logEvent({ name: 'FFV2:AddNewCloudFeature', properties: { response: response } });
                        const result = {
                            flightId: flight.rolloutId,
                            flightName: flight.rolloutName,
                            cloud: selectedCloud ?? '',
                            message: 'Successfully updated.',
                            icon: 'CheckMark'
                        };
                        onResultsUpdate([result]);
                        successCount++;
                    })
                    .catch((error) => {
                        appInsightsClient.logException({
                            exception: error,
                            properties: {
                                name: 'FFV2:AddNewCloudFeature',
                                message: `Failed to update rollout for flight ID: ${flight.rolloutId}`
                            }
                        });
                        const result = {
                            flightId: flight.rolloutId,
                            flightName: flight.rolloutName,
                            cloud: selectedCloud,
                            message: `Failed to update: ${(error as Error).message}`,
                            icon: 'ErrorBadge'
                        };
                        onResultsUpdate([result]);
                    })
                    .finally(() => {
                        setProcessingFlightMsg(null);
                    });
            }
            setResultMsg(`Update completed: ${successCount} out of ${totalFlights} flights were successfully updated.`);
            onDismiss();
        } else {
            setResultMsg('No flights available to process.');
        }
    }

    const handleAddCloud = () => {
        setIsDialogVisible(true);
    };

    const handleCloudChange = (cloud?: string) => {
        setSelectedCloud(cloud);
    };

    const handleReset = () => {
        setSelectedCloud('');
        onResultsUpdate([]);
        setProcessingFlightMsg(null);
        setResultMsg('');
    };

    const handleDismiss = () => {
        handleReset();
        props.onDismiss();
    };

    const handleDialogConfirm = () => {
        setIsDialogVisible(false);
        handleSubmit();
    };

    const handleDialogDismiss = () => {
        setIsDialogVisible(false);
    };

    return (
        <Modal isOpen={props.isOpen} onDismiss={handleDismiss}>
            <div className={formStyles.header}>
                <h3>Add New Feature Cloud To Running Flights</h3>
                <IconButton styles={iconButtonStyles} iconProps={cancelIcon} onClick={handleDismiss} />
            </div>

            {loadState === 'loading' && (
                <Spinner
                    size={SpinnerSize.medium}
                    label="Loading form data..."
                    styles={{
                        root: {
                            marginTop: '20px'
                        }
                    }}
                />
            )}

            <div>
                <MessageBar delayedRender={false} messageBarType={MessageBarType.severeWarning} styles={{ root: { maxWidth: 800 } }}>
                    Note: The new cloud will be applied to all flights that are currently in a &apos;Running&apos; state, and have reached
                    100% completion in Ring 4 (R4).
                </MessageBar>
            </div>

            <Stack tokens={{ padding: 20 }}>
                <Stack.Item>
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ ...editFlightTableCellStyles, alignItems: 'center', display: 'flex' }}>
                                    <Label required>New Cloud:</Label>
                                </td>
                                <td style={{ ...editFlightTableCellStyles, width: '200px' }}>
                                    <CloudsDropdown
                                        cloudOptions={availiableClouds}
                                        selectedCloud={selectedCloud}
                                        updateCloud={handleCloudChange}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal tokens={gapStackTokensMedium}>
                        <Stack.Item styles={stackItemStyles}>
                            <PrimaryButton
                                text="Add"
                                iconProps={{ iconName: 'cloud' }}
                                onClick={handleAddCloud}
                                styles={formButtonStyles}
                                allowDisabledFocus
                                disabled={!selectedCloud || selectedCloud.trim() === ''}
                            />
                        </Stack.Item>
                        <Stack.Item styles={stackItemStyles}>
                            <DefaultButton
                                text="Reset"
                                iconProps={{ iconName: 'Clear' }}
                                onClick={handleReset}
                                styles={formButtonStyles}
                                allowDisabledFocus
                            />
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>

            <Stack tokens={{ padding: 20 }}>
                {processingFlightMsg && <ProgressIndicator label={processingFlightMsg} styles={progressIndicatorCustomStyles} />}
            </Stack>

            <Dialog
                hidden={!isDialogVisible}
                onDismiss={handleDialogDismiss}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: `Confirm Adding ${selectedCloud?.toUpperCase()} Cloud`,
                    subText: 'Are you sure you want to add this cloud to all running flights?'
                }}
                modalProps={{
                    isBlocking: true
                }}
            >
                <DialogFooter>
                    <PrimaryButton onClick={handleDialogConfirm} text="Yes" />
                    <DefaultButton onClick={handleDialogDismiss} text="No" />
                </DialogFooter>
            </Dialog>
        </Modal>
    );
};

export default AddNewCloudForm;
