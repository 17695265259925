import { Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import React from 'react';

import { disabledExperiences } from '../configs/defaults';
import { gapStackTokensMedium } from '../styles/FFv2Style';

type CloudsDropdownProps = {
    cloudOptions: string[] | undefined;
    selectedCloud?: string;
    updateCloud: (value?: string) => void;
};

/**
 * Renders a dropdown to select cloud options.
 *
 * @param props - The props containing cloudOptions and updateCloud function.
 * @returns The JSX element representing the cloud options dropdown.
 */
const CloudsDropdown: React.FC<CloudsDropdownProps> = (props) => {
    const { cloudOptions, selectedCloud, updateCloud } = props;

    const options = (cloudOptions || []).map((key) => ({
        key: key,
        text: key,
        disabled: disabledExperiences.includes(key)
    }));

    const onChangeInput = (_?: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
        if (item) {
            updateCloud(item.key.toString());
        }
    };

    return (
        <Stack tokens={gapStackTokensMedium}>
            <Dropdown options={options} selectedKey={selectedCloud} placeholder="Select cloud" onChange={onChangeInput} />
        </Stack>
    );
};

export default CloudsDropdown;
