import { msalInstance } from '../App';
import { UserContextType } from '../pages/FFv2/contexts/UserContext';
import { GroupInfo, UserInfo } from '../pages/FFv2/types/Types';
import MsGraphService from '../services/msGraph.service';

import type { User } from '@microsoft/microsoft-graph-types';

/**
 * Gets the username of the currently logged-in user.
 *
 * @returns The username.
 */
export function getUserPrincipalName(): string {
    return msalInstance.getAllAccounts()[0].username;
}

/**
 * Gets the display name of the currently logged-in user.
 *
 * @returns The full name.
 */
export function getUserDisplayName(): string {
    return msalInstance.getAllAccounts()[0].name || '';
}

/**
 * Gets the id of the currently logged-in user.
 *
 * @returns The id.
 */
export function getUserId(): string {
    return msalInstance.getAllAccounts()[0].idTokenClaims?.oid || '';
}

/**
 * Gets the user info of the currently logged-in user.
 *
 * @returns The user info.
 */
export function getUserInfo(): UserInfo {
    return { id: getUserId(), displayName: getUserDisplayName(), principalName: getUserPrincipalName() };
}

/**
 * Converts a user object to a user info object.
 *
 * @param user The MSGraph user object.
 * @returns The user info object.
 */
export function convertUserToUserInfo(user: User): UserInfo {
    if (!user.id || !user.displayName || !user.userPrincipalName) {
        throw new Error('Invalid user object');
    }
    return { id: user.id, displayName: user.displayName, principalName: user.userPrincipalName };
}

/**
 * Gets the user context of the currently logged-in user.
 *
 * @returns The user context.
 */
export async function getUserContext(): Promise<UserContextType> {
    const msGraphService = new MsGraphService();
    const securityGroups = (await msGraphService.getTransitiveMemberOf(undefined, 'id, displayName')) as GroupInfo[];
    const directReports = (await msGraphService.getDirectReports(undefined, 'jobTitle ne null', 'id, displayName, userPrincipalName')).map(
        (report) => convertUserToUserInfo(report)
    );
    const manager = await msGraphService.getManager().then((user) => convertUserToUserInfo(user));
    const skip = await msGraphService.getManager(manager.id).then((user) => convertUserToUserInfo(user));
    const peers = await msGraphService
        .getDirectReports(manager.id, 'jobTitle ne null', 'id, displayName, userPrincipalName')
        .then((response) => response.map((user) => convertUserToUserInfo(user)).filter((peer) => peer.id !== getUserId()));
    return {
        securityGroups,
        directReports,
        peers,
        manager,
        skip
    };
}
